.project {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-reverse {
  width: 80%;
  margin-left: 7rem;
  margin-right: 7rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.__img_wrapper {
  width: 60em;
}

.__img_wrapper img {
  width: 100%;
  border-radius: 8px;
  transition: transform 0.2s;
}

.__img_wrapper img:hover {
  transform: scale(1.05);
}

.__content_wrapper {
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.51);
}

.title {
  font-size: 1.5rem;
  margin: 0;
}

.description {
  font-size: 1rem;
  width: 60%;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: rgba(49, 217, 217, 1); /* Changez cette couleur pour celle que vous préférez */
  color: white;
}

.carousel .control-arrow:hover {
  background: rgb(69, 121, 189);
}

.carousel .control-prev.control-arrow::before,
.carousel .control-next.control-arrow::before {
  border-top-color: white; /* Changez la couleur de la flèche ici */
  border-right-color: white; /* Changez la couleur de la flèche ici */
}

button {
  background: rgba(49, 217, 217, 1);
    color: white;
  border: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 1em;
  position: relative;
  z-index: 3;
}

button:hover {
  background: rgb(69, 121, 189);
}

.react-responsive-carousel .carousel .slide img {
  border-radius: 30px;
}

@media (max-width: 1024px) {
  .project, .project-reverse {
    margin-left: 3rem;
    margin-right: 3rem;
    flex-direction: column;
    width: 90%;
  }
  .__img_wrapper {
    width: 100%;
  }
  .__content_wrapper {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .project, .project-reverse {
    margin-left: 1rem;
    margin-right: 1rem;
    flex-direction: column;
    width: 95%;
  }
  .__img_wrapper {
    width: 100%;
  }
  .__content_wrapper {
    width: 100%;
  }
}
